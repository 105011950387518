import React from 'react';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';

class Announcement extends React.Component {
  constructor(props) {
    super(props);
  }

  showPreviousAnnouncement(datefield, duration) {
    const startDate = moment(datefield.value);
    const endDate = moment(Date.now());
    const dateDiff = endDate.diff(startDate, 'days');
    if (dateDiff < duration) {
      return true;
    }
    return false;
  }

  render() {
    const { fields } = this.props;
    return (
      <div>
        {this.showPreviousAnnouncement(
          fields.releaseDate,
          fields.duration.value,
        ) &&
          fields.description.value && (
            <div className="announcement">
              <p className="l-padding">
                <Image field={fields.icon} className="announcement-icon" />
                <RichText
                  tag="span"
                  field={fields.description}
                  className="content"
                />
              </p>
            </div>
          )}
      </div>
    );
  }
}
export default Announcement;
